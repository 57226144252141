<template>
  <v-card
    :title="$t('components.DeleteVouchers.title')"
    data-pw="deleteVouchersCard"
  >
    <v-card-text>
      <v-card class="bg-red-lighten-5 rounded border mt-3" variant="outlined">
        <v-card-text>
          <div class="mb-2">
            <v-icon class="mr-2" color="red" size="small">fa-solid fa-exclamation-circle</v-icon>
            <span class="text-red font-weight-bold">
              {{ $t("components.ResetAssessmentDialog.danger_zone") }}
            </span> <span>|
              {{ $t("components.ResetAssessmentDialog.action_label") }}</span>
          </div>
          <span class="text-red font-weight-bold">
            {{ $t('components.DeleteVouchers.warning_message', {quantity: multipleVoucherIds!.length}) }}
          </span>
          <v-text-field
            v-if="multipleVoucherIds"
            v-model="verifyString"
            density="compact"
            variant="outlined"
            :hide-details="true"
            class="mb-2 mt-4"
            data-pw="confirmDeleteVoucherTextField"
          />
          <span>{{ $t('common.type_confirm_message') }}</span>

        </v-card-text>
      </v-card>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn-secondary
        data-pw="cancelButton"
        @click="$emit('close')"
      >
        {{ $t("buttons.cancel") }}
      </v-btn-secondary>
      <v-btn-delete
        data-pw="confirmDeleteButton"
        @click="deleteVouchers"
        v-bind:disabled="verifyString !== 'CONFIRM'"
      >
        {{ $t("buttons.confirm") }}
      </v-btn-delete>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import {ref} from "vue";
import yapi from "@/lib/yapi";
import router from "@/router";
import {isYapiError, YapiError} from "@YenzaCT/sdk";
import {useGlobalStore} from "@/store";

const props = defineProps({
  multipleVoucherIds: {
    type: Array,
    required: false
  }
});

const emits = defineEmits<{
  (e: "confirm"): void;
  (e: "close"): void;
}>();

const store = useGlobalStore();

const verifyString = ref("");

const deleteVouchers = async () => {
  try {
    await yapi.admin.accessCode.removeMultiple(
      props.multipleVoucherIds as string[]
    );
    emits("close");
    await router.push({name: "vouchers"});
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  }
};
</script>

<style scoped>

</style>
