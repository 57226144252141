<template>
  <authenticated-layout>
    <template #headerIcon>
      far fa-ticket
    </template>
    <template #header>
      {{ accessCode?.code }}
    </template>
    <template #breadcrumbs>
      <v-breadcrumbs density="compact" class="pl-0 text-caption">
        <v-breadcrumbs-item :to="{ name: 'vouchers' }" class="font-weight-bold" color="secondary">
          {{ $t("components.AccessCodeDetailPage.back_breadcrumb") }}
        </v-breadcrumbs-item>
        <v-breadcrumbs-divider/>
        <v-breadcrumbs-item color="secondary">
          {{ accessCode?.code }}
        </v-breadcrumbs-item>
      </v-breadcrumbs>
    </template>
    <template #context-menu>
      <v-menu
        v-if="isAdminOrRoot"
        bottom
        transition="scale-transition"
        prepend-icon="fa fa-bars"
      >
        <template v-slot:activator="{ props }">
          <v-btn-primary
            v-bind="props"
            data-pw="menuButton"
          >
            {{ $t("buttons.menu") }}
          </v-btn-primary>
        </template>
        <v-list>
          <v-list-item
            :disabled="!canEdit"
            data-pw="changeAccountType"
            @click="showChangeAccountType = true"
          >
            {{ $t("components.AccessCodeDetailPage.change_account_type") }}
          </v-list-item>
          <v-list-item
            :disabled="!canEdit"
            data-pw="changeResearchAssessments"
            @click="showChangeResearch = true"
          >
            {{ $t("components.AccessCodeDetailPage.change_research_assessments") }}
          </v-list-item>
          <v-list-item
            :disabled="!canEdit"
            data-pw="deleteVoucher"
            @click="showDeleteVoucher = true"
          >
            {{ $t("components.AccessCodeDetailPage.delete_voucher") }}
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <v-container fluid class="py-4">
      <v-row>
        <v-col cols="6">
          <AccessCodeDetailsCard
            data-pw="voucherCardTemplate"
            v-if="accessCode"
            :access-code="accessCode"
          />
        </v-col>
        <v-col cols="6" v-if="user">
          <profile-card
            :account-type="$t(`accountTypes.${user.app.accountType}`)"
            :role="$t(`roles.${user.app.role}`)"
            :user="user"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="showChangeAccountType" :persistent="true" max-width="500">
      <ChangeAccountType
        @close="onCloseEditAccountTypeDialog"
        :title="$t('components.AccessCodeDetailPage.change_account_type')"
        :user-choice="true"
        data-pw="changeAccountTypeDialog"
      />
    </v-dialog>
    <v-dialog v-model="showDeleteVoucher" persistent max-width="500">
      <delete-voucher
        @close="onCloseDeleteVoucherDialog"
        :title="$t('components.AccessCodeDetailPage.delete_voucher')"
        :user-choice="true"
        :confirm-string="accessCode?.code!"
        :message="$t('components.AccessCodeDetailPage.message_warning')"
        data-pw="deleteVoucherDialog"
      />
    </v-dialog>
    <v-dialog v-model="showChangeResearch" persistent max-width="500">
      <change-research
        v-if="accessCode"
        @close="onCloseChangeResearchDialog"
        :title="$t('components.AccessCodeDetailPage.change_research_assessments')"
        :user-choice="true"
        data-pw="changeResearchDialog"
        :access-code="accessCode"
      ></change-research>
    </v-dialog>
  </authenticated-layout>
</template>

<script setup lang="ts">
import {computed, onMounted, ref, Ref, watch} from "vue";
import {AccessCode, isYapiError, Role, DashUser, YapiError} from "@YenzaCT/sdk";
import {useRoute} from "vue-router";
import AuthenticatedLayout from "@/layout/AuthenticatedLayout.vue";
import AccessCodeDetailsCard from "@/pages/AccessCodeTablePage/components/AccessCodeDetailsCard.vue";

import yapi from "@/lib/yapi";
import {useGlobalStore} from "@/store";
import ChangeAccountType from "@/components/Voucher/ChangeAccountType.vue";
import deleteVoucher from "@/components/Voucher/DeleteVoucher.vue";
import changeResearch from "@/components/Voucher/ChangeResearchAssessment.vue";
import ProfileCard from "@/pages/UserDetailPage/components/profile/ProfileCard.vue";

const route = useRoute();
const store = useGlobalStore();
const accessCode = ref<AccessCode>();
const voucherId: Ref<string> = ref("");
const user = ref<DashUser>();

const isAdminOrRoot = ref(store.user?.app.role === Role.Admin || store.user?.app.role === Role.Root);
const showChangeAccountType = ref(false);
const showDeleteVoucher = ref(false);
const showChangeResearch = ref(false);

const canEdit = computed(() => accessCode.value?.usedAt === undefined);

async function fetchAccessCode() {
  try {
    store.networkBusy = true;
    voucherId.value = route.params.id as string;
    accessCode.value = (await yapi.admin.accessCode.get(voucherId.value)).data;
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
}

async function fetchUser() {
  try {
    store.networkBusy = true;
    if (accessCode.value?.user && typeof accessCode.value.user === "string")
      user.value = (await yapi.admin.user.get(accessCode.value.user)).data;
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
}

onMounted(async () => {
  await fetchAccessCode();
  await fetchUser();
});

watch(() => route.params.id, async (newId, oldId) => {
  if (newId !== oldId) {
    await fetchAccessCode();
    await fetchUser();
  }
});

async function onCloseEditAccountTypeDialog() {
  showChangeAccountType.value = false;
  await fetchAccessCode();
}

async function onCloseDeleteVoucherDialog() {
  showDeleteVoucher.value = false;
  await fetchAccessCode();
}

async function onCloseChangeResearchDialog() {
  showChangeResearch.value = false;
  await fetchAccessCode();
}
</script>
